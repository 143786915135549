const menu_items = Array.from(document.getElementsByClassName('menu-item'))

menu_items.forEach(e => {
    e.addEventListener('mouseenter', entrar)
    e.addEventListener('mouseleave', salir)
})

function entrar(e) {
    const target = document.getElementById(e.currentTarget.dataset.target)
    target.classList.add('enter')
}

function salir(e) {
    const target = document.getElementById(e.currentTarget.dataset.target)
    target.classList.remove('enter')
}



// Variable para almacenar la posición anterior del scroll
let prevScrollPos = window.pageYOffset;

// Función para agregar o quitar la clase dependiendo de la dirección del scroll
function toggleScrollClass() {
  const currentScrollPos = window.pageYOffset;

  if (currentScrollPos > prevScrollPos) {
    // Scroll hacia abajo
    document.body.classList.add('scroll-down');
    document.body.classList.remove('scroll-up');
  } else {
    // Scroll hacia arriba
    document.body.classList.add('scroll-up');
    document.body.classList.remove('scroll-down');
  }

  prevScrollPos = currentScrollPos;
}

// Función para verificar si el scroll se ha detenido
function checkScrollStop() {
  clearTimeout(scrollTimer);
  scrollTimer = setTimeout(function() {
    document.body.classList.remove('scroll-up');
    document.body.classList.remove('scroll-down');
  }, 200);
}

// Evento cuando se hace scroll
window.addEventListener('scroll', function() {
  toggleScrollClass();
  checkScrollStop();
});

// Evento cuando se detiene el scroll
let scrollTimer;
window.addEventListener('scroll', function() {
  clearTimeout(scrollTimer);
  scrollTimer = setTimeout(checkScrollStop, 200);
});



document.addEventListener("DOMContentLoaded", function() {
  var sections = document.querySelectorAll('.single-orbitas #section-1');

  sections.forEach(function(section) {
    if (section.querySelector('.section-projects-slider')) {
      section.classList.add('fix-padding');
    }
  });
});