class OpenModal {
  constructor() {
    this._openModal();
  }
  // OPEN MODAL
  _openModal() {
    $(document).ready(function () {
      setTimeout(function () {
        $(".open-modal").trigger('click');
      }, 2000);
    });

  }
}