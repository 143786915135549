class Progress{
    constructor(scope, target){
        this.target = target
        this.scroll = Scrollbar.get(scope.find('#page-wrapper')[0])
        this.alturaFooter = document.querySelector('#page-footer').offsetHeight
        this.init()
    }
    init(){
        this.scroll.addListener(this.update.bind(this))
    }

    update(status){
        const porcentaje = status.offset.y / (status.limit.y - this.alturaFooter)
        this.target.forEach(element => {
            element.innerHTML= porcentaje < 1 ? `<svg height="80" width="80">
          <circle cx="40" cy="40" r="30" stroke="red" stroke-width="7" stroke-dasharray="188" stroke-dashoffset="${188-188*porcentaje}" fill="none" />
          <text x="23" y="45" fill="black">${Math.floor(porcentaje * 101)}%</text>
        </svg>` : ''
        });
    }
}