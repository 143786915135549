class AddClass{
  constructor() {
    this._imgOrbits();
  }
  _imgOrbits() {

    $('.orb-1').hover(
       function(){ $('.orbita').addClass('hover-orb-1') },
       function(){ $('.orbita').removeClass('hover-orb-1') },
       //function(){ $(this).addClass('hover') },
       //function(){ $(this).removeClass('hover') }
    );
    $('.orb-2').hover(
       function(){ $('.orbita').addClass('hover-orb-2') },
       function(){ $('.orbita').removeClass('hover-orb-2') },
    );
    $('.orb-3').hover(
       function(){ $('.orbita').addClass('hover-orb-3') },
       function(){ $('.orbita').removeClass('hover-orb-3') },
    );
    
  } 
}


