class SwipperCustom {
  constructor() {
    this._swiperCarousel();
  }
  _swiperCarousel() {

    var swiper = new Swiper(".swiperCarousel", {
      spaceBetween: 30,
      grabCursor: true,
      slidesPerView: "auto",
      //loop: true,
      //centeredSlides: true,
      //navigation: {
        //nextEl: ".swiper-button-next",
        //prevEl: ".swiper-button-prev",
      //},
      
    });

  }
}